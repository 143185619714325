import React from "react";
import "./Esporttheme.css";

function Esporttheme() {
  return (
    <div className="paraghraph_text">
      <div>
        <span className="red_pahara">
          Ecommerce store <br />
        </span>
        <span className="red_pahara">
          throw a shopify theme store ( esports theme )
        </span>
      </div>
    </div>
  );
}
export default Esporttheme;
